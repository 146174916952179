define([
    'angular'

 ], function () {
    'use strict';
    
    
    var controllers = angular.module('app.F-SchddCtrl', [])
    .controller('FScheduleDController', ['GlobalService', 'JsonObjectFactory', '$parse','$scope','$rootScope', '$timeout', '$uibModal',
        'workspaceFactory', '$http', '$filter', '$q', 'AlertService', 'USER_SETTINGS', 'GENERAL_CONFIG', 'FSchddFactory', ,'ReportableTransactionFactory', 'ModalFactory',FScheduleDController])
    .controller('FScheduleDControllerModal', ['GlobalService', 'JsonObjectFactory', '$parse','$scope','$rootScope', '$timeout', '$uibModal',
        'workspaceFactory', '$http', '$filter', '$q', 'AlertService', 'USER_SETTINGS', 'GENERAL_CONFIG', 'FSchddFactory', 'ReportableTransactionFactory', 'ModalFactory','$uibModalInstance','rowData', 'colData', 'gridData',FScheduleDControllerModal])    ;

    function FScheduleDController(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,$uibModal,workspaceFactory,$http,$filter,$q,AlertService,USER_SETTINGS,GENERAL_CONFIG,FSchddFactory,ReportableTransactionFactory,ModalFactory) {
    	FScheduleDControllerModal(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,$uibModal,workspaceFactory,$http,$filter,$q,AlertService,USER_SETTINGS,GENERAL_CONFIG,FSchddFactory,ReportableTransactionFactory,ModalFactory,{},{}, {}, {},this);
    }


	function FScheduleDControllerModal(GlobalService,JsonObjectFactory,$parse,$scope,$rootScope,$timeout,$uibModal,workspaceFactory,$http,$filter,$q,AlertService,USER_SETTINGS,GENERAL_CONFIG,FSchddFactory,ReportableTransactionFactory,ModalFactory,$uibModalInstance,
        rowData, colData, gridData,thisObj) {
		console.log("------------At the start-----------");
		
		var vm = this;
		vm.rowData = rowData;
		vm.globalParams = GlobalService.globalParams;	
		vm.scenario 		    = GlobalService.globalParams.scenario;
		vm.glbl_issue_id  		= GlobalService.globalParams.issue_id;
		vm.local_issue_id  		= '';
		vm.bShowSaveButton 		= true;
		vm.issueData			= [];
		vm.cur_scenario_type    = '';
		var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
		console.log("The Global Params like tax_year, scenario and jcd are ::::::::::::", vm.globalParams);
		console.log(rowData);
		vm.scheduleDHeader = [];
		vm.scheduleDList = [];
		vm.scheduleDOriginalData = [];
		vm.scheduleDFormData = [];
		vm.schedulDColumnHeader = [];
		vm.scheduleDFinalData = [];
		vm.unAllocatedAmt = 0;
		vm.crudLoading = false;
		vm.codeAdj = ['B', 'T', 'N', 'H', 'D', 'Q', 'X', 'R', 'W', 'L', 'E', 'S', 'C', 'M', 'O', 'Z', 'Y'];
		vm.isValidScenario = false;
		vm.isExamScenario = GlobalService.inputs.tax_year.selected.scenario_type_code === 'EXAM' && checkExamScenario();
		vm.categoryCode = {};
		function checkExamScenario() {
			const filters = workspaceFactory.activeScreen.filters && workspaceFactory.activeScreen.filters.getFilters();
			if (!filters) {
				return false;
			}
			const isExamScenario = filters.some((filter) => {
				return filter.param_name.trim().toLowerCase() === 'scenario' &&
					filter.selected.CODE &&
					filter.selected.CODE.trim().toUpperCase() === 'EXAM';
			});
			return isExamScenario;
		}
		
		vm.cancelModal = function(){
			ReportableTransactionFactory.finalRepTransaction = [];
			$uibModalInstance.dismiss('cancel');
		};
		
		vm.cancel = function() {
			console.log("Form Schedule D data from DB is :::::::::::", vm.scheduleDFormData);
			console.log("Form Schedule D data from original Data is saved in :::::::::::", vm.scheduleDOriginalData);
			if(angular.equals(vm.scheduleDFormData, vm.scheduleDOriginalData)){
				vm.cancelModal();
			}else{
				if(vm.crudLoading){
					AlertService.add("info", "The transaction is still in progress", 4000);
					vm.cancelModal();
				}
				else if(confirm("Capital Gain or (Loss) Information is not save. Do you want to close Form without saving changes?")){
					vm.cancelModal();
				}
			}
			
		};
		
		vm.userSettings = USER_SETTINGS;
		vm.logged_in_user = vm.userSettings.user.sso_id;
		console.log(vm.logged_in_user);
		function getFSchddData(){
			FSchddFactory.fetchSchddDetails(rowData, vm.logged_in_user, filterParams.scenario).then(function (data){
				console.log("Came back to controller from Service...............:::::::::", data);				
				vm.scheduleDHeader = data.jsonObject[0];						
				vm.scheduleDList = data.jsonObject.slice(1);
				for(var i=0;i<vm.scheduleDList.length;i++){
					if(!vm.isValidScenario && vm.scheduleDList[i].T_SCENARIO_TYPE_CODE){
						vm.isValidScenario = vm.checkEditScenario(vm.scheduleDList[i].T_SCENARIO_TYPE_CODE,  vm.scheduleDList[i].T_SCENARIO,  vm.scheduleDList[i].ISSUE_KEY);
					}
				}
				var o = vm.scheduleDList.reduce( (a,b) => {
					a[b.LINE_ORDER] = a[b.LINE_ORDER] || [];
					a[b.LINE_ORDER].push(b);
					return a;
				}, {});
				
				var a = Object.keys(o).map(function(k){
					return {ROW_TYPE: o[k][0].ROW_TYPE, DELETE_ROWS: [], Line_data: o[k]};
				});
				
				vm.scheduleDFormData = a;
				console.log(vm.scheduleDFormData);
				
				var c = [];
				
				for (var i=0; i < vm.scheduleDFormData.length; i++){
					if(vm.scheduleDFormData[i].ROW_TYPE == 'CH'){
						
						if(vm.scheduleDFormData[i].Line_data[0].OCCURRENCE != null){
							var z = vm.scheduleDFormData[i].Line_data.reduce( (a,b) => {
								a[b.OCCURRENCE] = a[b.OCCURRENCE] || [];
								a[b.OCCURRENCE].push(b);
								return a;
							}, {});
							
							var y = Object.keys(z).map(function(k){
								return {Line_Type: "DATA_ROW", Is_Multi: z[k][0].IS_MULTI, Line_no: z[k][0].LINE_NO, IS_EDITABLE: z[k][0].IS_EDITABLE, IS_EDITTED:"N", Trans_details_key: z[k][0].TRANS_DETAILS_KEY, Occurrence: z[k][0].OCCURRENCE, Line_data_occ: z[k]};
							});
							
							console.log("At this point of the value of y is -------::::::", y);
							var m = angular.copy(y);
							console.log("At this point of the value of m is -------::::::", m);
							
							for(var x=0; x < m.length; x++){
								var d = (2*x)+1;
								y.splice(d, 0, m[x]);
								console.log("At this point of the value of y inside loop is -------::::::", y);
								y[d].Line_Type = "DATA_COMMENTS";
								y[d].Line_data_occ = [y[(2*x)].Line_data_occ[y[(2*x)].Line_data_occ.length -1]];
							}
							
							c = angular.copy(y[0]);
							c.Line_Type = "HEADER_ROW";
							c.Occurrence = "";
							c.Trans_details_key = "";
							c.IS_EDITABLE = "Y";
							for (var j=0; j < c.Line_data_occ.length; j++){
								c.Line_data_occ[j].ATTRIB_VALUE = c.Line_data_occ[j].ATTRIB_DESCRIPTION;
							}
							
							y.unshift(c, {Line_Type:"SUB_ROW", Line_no:c.Line_no, Line_data_occ: []});
							vm.scheduleDFormData[i].Line_data = angular.copy(y);
							
						} else {
							var z = vm.scheduleDFormData[i].Line_data.reduce( (a,b) => {
								a[b.LINE_NO] = a[b.LINE_NO] || [];
								a[b.LINE_NO].push(b);
								return a;
							}, {});
							
							var y = Object.keys(z).map(function(k){
								return {Line_Type: "HEADER_ROW", Is_Multi: z[k][0].IS_MULTI, Line_no: z[k][0].LINE_NO, IS_EDITABLE: z[k][0].IS_EDITABLE, IS_EDITTED:"N", Trans_details_key: "", Occurrence: "", Line_data_occ: z[k]};
							});
							
							for(var j=0; j < y[0].Line_data_occ.length; j++){
								y[0].Line_data_occ[j].ATTRIB_VALUE = y[0].Line_data_occ[j].ATTRIB_DESCRIPTION;
							}
							
							y.push({Line_Type:"SUB_ROW", Line_no:y[0].Line_no, Line_data_occ: []});
							vm.scheduleDFormData[i].Line_data = y;
						}
					} else if(vm.scheduleDFormData[i].ROW_TYPE == 'DT'){
						for(var j=0; j < vm.scheduleDFormData[i].Line_data.length; j++){
							if(vm.scheduleDFormData[i].Line_data[j].IS_EDITABLE == 'Y' && vm.scheduleDFormData[i].Line_data[j].EDIT_TYPE == 'currency' && vm.scheduleDFormData[i].Line_data[j].DATA_TYPE == 'number'){
								vm.scheduleDFormData[i].Line_data[j].ATTRIB_VALUE = parseInt(vm.scheduleDFormData[i].Line_data[j].ATTRIB_VALUE);
							}
							if(vm.scheduleDFormData[i].Line_data[j].IS_EDITABLE == 'Y' && vm.scheduleDFormData[i].Line_data[j].ATTRIB_SHORT_CODE == 'LINE_NO_12'  && vm.scheduleDFormData[i].Line_data[j].T_SCENARIO == vm.globalParams.scenario && vm.globalParams.tax_year >= 2023 && vm.rowData.CONSOL_GROUP_KEY == "3"){
								vm.scheduleDFormData[i].Line_data[j].ATTRIB_VALUE = '11';
							}
						}
					}
				}
				
				console.log(vm.scheduleDFormData);
				vm.scheduleDOriginalData = angular.copy(vm.scheduleDFormData);
				getUnallocatedAmt();
			});
			
		}
		getFSchddData();
		getCategoryCodeDropdown();
		vm.reset = function(){
			getFSchddData();
		}
		
		vm.save = function(){
			vm.finalData = [];
			vm.clob_settings = [];
			console.log(vm.scheduleDFormData);
			console.log("Original Data was ::::::::==========", vm.scheduleDOriginalData);
			if(vm.crudLoading){
				AlertService.add("info", "Please wait while we save this request", 4000);
				return;
			}
			vm.scheduleDFinalData = angular.copy(vm.scheduleDFormData);
			if(angular.equals(vm.scheduleDFormData, vm.scheduleDOriginalData)){
				vm.cancel();
			}else{
				updateFormDataValues();
				var returnValue = validateFormValues();
				if(returnValue == 1){
					return false;
				}
				if(vm.unAllocatedAmt != 0){
					if(!confirm("Allocated Amount is not fully adjusted among Capital Gain or (Loss). Confirm to save the changes.")){
						return false;
					}
				}
				for(var x=0; x < vm.scheduleDFinalData.length; x++){
					if(vm.scheduleDFinalData[x].ROW_TYPE == 'CH'){
						vm.scheduleDFinalData[x].Line_data.splice(0, 2);
						if(vm.scheduleDFinalData[x].Line_data.length != null && vm.scheduleDFinalData[x].Line_data.length != undefined){
							for(var j=0; j < vm.scheduleDFinalData[x].Line_data.length; j++){
								vm.scheduleDFinalData[x].Line_data[j].Line_data_occ[vm.scheduleDFinalData[x].Line_data[j].Line_data_occ.length-1] = vm.scheduleDFinalData[x].Line_data[j+1].Line_data_occ[0];
								vm.scheduleDFinalData[x].Line_data.splice(j+1, 1);
								/*if(vm.scheduleDFinalData[x].Line_data[j].IS_EDITTED == "Y" && (vm.scheduleDFinalData[x].Line_data[j].Occurrence == "" || vm.scheduleDFinalData[x].Line_data[j].Occurrence == null)){
									vm.finalData.push(vm.scheduleDFinalData[x].Line_data[j].Line_data_occ);
								}else if (vm.scheduleDFinalData[x].Line_data[j].IS_EDITTED == "Y" && (vm.scheduleDFinalData[x].Line_data[j].Occurrence != "" && vm.scheduleDFinalData[x].Line_data[j].Occurrence != null)){
									vm.finalData.push(vm.scheduleDFinalData[x].Line_data[j].Line_data_occ);
								}*/
								for(var k = 0; k < vm.scheduleDFinalData[x].Line_data[j].Line_data_occ.length; k++){
									vm.finalData.push(vm.scheduleDFinalData[x].Line_data[j].Line_data_occ[k]);
								}
							}
						}
						if(vm.scheduleDFinalData[x].DELETE_ROWS.length != null && vm.scheduleDFinalData[x].DELETE_ROWS.length != undefined){
							for(var j=0; j < vm.scheduleDFinalData[x].DELETE_ROWS.length; j++){
								for(var k = 0; k < vm.scheduleDFinalData[x].DELETE_ROWS[j].Line_data_occ.length; k++){
									vm.scheduleDFinalData[x].DELETE_ROWS[j].Line_data_occ[k].IS_CHANGED_FLAG = "D";
									vm.finalData.push(vm.scheduleDFinalData[x].DELETE_ROWS[j].Line_data_occ[k]);
								}
							}
						}
					}
					if(vm.scheduleDFinalData[x].ROW_TYPE == 'TH' || vm.scheduleDFinalData[x].ROW_TYPE == 'DT'){
						for(var j=0; j < vm.scheduleDFinalData[x].Line_data.length; j++){
							vm.finalData.push(vm.scheduleDFinalData[x].Line_data[j]);
						}
					}
				}
				
				console.log("After changes, the final data was :::::::::::=========", vm.scheduleDFinalData);
				console.log("After changes, the manipulated data was :::::::::::=========", vm.scheduleDFormData);
				console.log("After changes, the final data to send DB was :::::::::::=========", vm.finalData);
				vm.clob_settings = [{sso_id: vm.logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
				sendsaveDetails(vm.finalData, vm.clob_settings);
			}
		}

		function getCategoryCodeDropdown() {
			FSchddFactory.fetchCategoryCodeDropdownValues(rowData, vm.logged_in_user).then((data) => {
				console.log(data);
				console.log("ClassificationDropdown::Came back to controller from Service...............:::::::::", data);
				data.jsonObject.forEach(element => {
					vm.categoryCode[element.CODE_NAME] = element.CODE_DESC; 
				});
			});

		}
		
		function sendsaveDetails(final_data, clob_settings){
			var schdSave = null;
			var message = "Schedule D Details are successfully updated.";
			var rep_message = "Reportable Transaction Saved Successfully.";
			//console.log("This was from the reportable Transaction service Factory, the rep trnascation data was :::::", ReportableTransactionFactory.finalRepTransaction);
			//var repTrans_data = ReportableTransactionFactory.finalRepTransaction;
			vm.crudLoading = true;
			FSchddFactory.saveSchddDetails(final_data, clob_settings).then(function (data){
				console.log("Result from data was ---------------::::", data);
				if(null != data.data.errorMessage && data.data.errorMessage != "null"){
					vm.crudLoading = false;
					console.log("There was the error message and the message is ", data.data.errorMessage);
					AlertService.add("error", data.data.errorMessage, 4000);
				}else {
					console.log("There wa no error message and the message was ", data.data.errorMessage);
					AlertService.add("success", message, 4000);
					vm.crudLoading = false;
					vm.cancelModal();
					var args = {};
					$rootScope.$emit('gridUpdate', args);
					/*if(repTrans_data != null && repTrans_data.length > 0){
						FSchddFactory.saveSchddReportableTransaction(rowData, repTrans_data, clob_settings).then(function (data){
							console.log("Result from rep Trans save data was ---------------::::", data);
							if(null != data.data.errorMessage && data.data.errorMessage != "null"){
								vm.crudLoading = false;
								console.log("There was the error message and the message is ", data.data.errorMessage);
								AlertService.add("error", data.data.errorMessage, 4000);
							}else {
								console.log("There wa no error message and the message was ", data.data.errorMessage);
								AlertService.add("success", rep_message, 4000);
								vm.crudLoading = false;
								vm.cancelModal();
								var args = {};
								$rootScope.$emit('gridUpdate', args);
							}
						});
					}else{
						vm.crudLoading = false;
						vm.cancelModal();
						var args = {};
						$rootScope.$emit('gridUpdate', args);
					}*/
				}
			});
		}
		
		function getDateFormat(date_value){
			console.log("The value of the date for the row that needs to convert is ::::::", date_value);
			var date_selected = null;
			if(date_value != "VARIOUS"){
				date_selected = ((date_value.getMonth() > 8) ? (date_value.getMonth() + 1) : ('0' + (date_value.getMonth() + 1))) + '/' + ((date_value.getDate() > 9) ? date_value.getDate() : ('0' + date_value.getDate())) + '/' + date_value.getFullYear();
			}else date_selected = "VARIOUS";
			
			return date_selected;
		}
		
		function updateFormDataValues(){
			for (var i=0; i < vm.scheduleDFinalData.length; i++){
				if(vm.scheduleDFinalData[i].ROW_TYPE == 'CH'){
					for(var j=0; j < vm.scheduleDFinalData[i].Line_data.length; j++){
						if(vm.scheduleDFinalData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW"){
							for(var l=0; l < vm.scheduleDFinalData[i].Line_data[j].Line_data_occ.length; l++){
								if(vm.scheduleDFinalData[i].Line_data[j].Line_data_occ[l].IS_EDITABLE_CELL == "Y" && vm.scheduleDFinalData[i].Line_data[j].Line_data_occ[l].DATA_TYPE == "dateVarious" && vm.scheduleDFinalData[i].Line_data[j].Line_data_occ[l].EDIT_TYPE == "custom_date_various"){
									vm.scheduleDFinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = getDateFormat(vm.scheduleDFinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE);
								} else if(vm.scheduleDFinalData[i].Line_data[j].Line_data_occ[l].IS_EDITABLE_CELL == "Y" && vm.scheduleDFinalData[i].Line_data[j].Line_data_occ[l].DATA_TYPE == "string" && vm.scheduleDFinalData[i].Line_data[j].Line_data_occ[l].EDIT_TYPE == "radio"){
									if(vm.scheduleDFinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE == null ||vm.scheduleDFinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE == undefined){
										vm.scheduleDFinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = "Temp";
									}
								}else if(vm.scheduleDFinalData[i].Line_data[j].Line_data_occ[l].IS_EDITABLE_CELL == "Y" && vm.scheduleDFinalData[i].Line_data[j].Line_data_occ[l].DATA_TYPE == "string" && vm.scheduleDFinalData[i].Line_data[j].Line_data_occ[l].EDIT_TYPE == "checkbox"){
									if(vm.scheduleDFinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE == null ||vm.scheduleDFinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE == undefined){
										vm.scheduleDFinalData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = "N";
									}
								}
							}
						}
					}
				}
			}
		}
		
		function validateFormValues(){
			var returnValue = 0;
			var dateSold = new Date();
			var dateAcquired = new Date();
			for (var i=0; i < vm.scheduleDFormData.length; i++){
				if(vm.scheduleDFormData[i].ROW_TYPE == 'CH'){
					for(var j=0; j < vm.scheduleDFormData[i].Line_data.length; j++){
						var line_no = vm.scheduleDFormData[i].Line_data[j].Line_no;
						var part_no = "I";
						if(line_no == "10" || line_no == "9" || line_no == "8b"){
							part_no = "II";
						}
						if(vm.scheduleDFormData[i].Line_data[j].Line_Type == "DATA_ROW" || vm.scheduleDFormData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW"){
							var property_desc_index = vm.scheduleDFormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "PROPERTY_DESC_"+line_no);
							var date_acquired_index = vm.scheduleDFormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "DATE_ACQ_"+line_no);
							var date_sold_index = vm.scheduleDFormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "DATE_SOLD_"+line_no);
							var sales_price_index = vm.scheduleDFormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "SALE_PRICE_"+line_no);
							var cost_basis_index = vm.scheduleDFormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "COST_BASIS_"+line_no);
							var code_instructions = vm.scheduleDFormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "ADJ_AMT_CODE_"+line_no);
							var amt_adjusted = vm.scheduleDFormData[i].Line_data[j].Line_data_occ.findIndex(x => x.ATTRIB_SHORT_CODE == "ADJS_AMT_"+line_no);
							if(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[property_desc_index].ATTRIB_VALUE == null || vm.scheduleDFormData[i].Line_data[j].Line_data_occ[property_desc_index].ATTRIB_VALUE == undefined ||vm.scheduleDFormData[i].Line_data[j].Line_data_occ[property_desc_index].ATTRIB_VALUE.length <=0){
								vm.scheduleDFormData[i].Line_data[j].Line_data_occ[property_desc_index].HAS_ERROR = "Y";
								vm.scheduleDFormData[i].Line_data[j].Line_data_occ[property_desc_index].ATTRIB_VALUE_ERROR = "Enter Description of Property.";
								returnValue = 1; 
							}
							if(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[code_instructions].ATTRIB_VALUE == "Z" && vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE == "VARIOUS" &&
									vm.scheduleDFormData[i].Line_data[j].Line_data_occ[sales_price_index].ATTRIB_VALUE == 0 && vm.scheduleDFormData[i].Line_data[j].Line_data_occ[cost_basis_index].ATTRIB_VALUE == 0){
								
							} else {
								if(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE != "VARIOUS"){
									if(typeof vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE === 'object' && vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE.getYear() > (parseInt(vm.globalParams.tax_year)-1900)){
										vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_acquired_index].HAS_ERROR = "Y";
										vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE_ERROR = "The Date Acquired year has to be current tax year or prior year.";
										returnValue = 1;
									} else if(typeof vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE === 'string' && vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE.match(/\d{4}$/)[0] > parseInt(vm.globalParams.tax_year)){
										vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_acquired_index].HAS_ERROR = "Y";
										vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE_ERROR = "The Date Acquired year has to be current tax year or prior year.";
										returnValue = 1;
									}
								}
								if(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE != "VARIOUS"){
									if(typeof vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE === 'object' && vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE.getYear() > (parseInt(vm.globalParams.tax_year)-1900)){
										vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_sold_index].HAS_ERROR = "Y";
										vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE_ERROR = "The Date Sold year has to be same as tax year.";
										returnValue = 1;
									} else if(typeof vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE === 'string' && vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE.match(/\d{4}$/)[0] > parseInt(vm.globalParams.tax_year)){
										vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_sold_index].HAS_ERROR = "Y";
										vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE_ERROR = "The Date Sold year has to be same as tax year.";
										returnValue = 1;
									}
								}
								if(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE != "VARIOUS" && vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE != "VARIOUS"){
									var date_qcuired =  Date.parse(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE);
									var date_sold = Date.parse(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE);
									if(date_qcuired > date_sold){
										vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_acquired_index].HAS_ERROR = "Y";
										vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE_ERROR = "The Date Acquired should be prior to Date Sold";
										returnValue = 1;
									}
									dateAcquired =  vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_acquired_index].ATTRIB_VALUE;
									dateSold = vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE;
									var diff_in_dates = getDiffDates(dateAcquired, dateSold);
									if(diff_in_dates > 365 && part_no == "I"){
										vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_sold_index].HAS_ERROR = "Y";
										vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE_ERROR = "Please enter the long Term Gain/Loss values in Part II.";
										returnValue = 1;
									} else if(diff_in_dates <= 365 && part_no == "II"){
										vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_sold_index].HAS_ERROR = "Y";
										vm.scheduleDFormData[i].Line_data[j].Line_data_occ[date_sold_index].ATTRIB_VALUE_ERROR = "Please enter the short Term Gain/Loss values in Part I.";
										returnValue = 1;
									}
								}
								if(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[cost_basis_index].ATTRIB_VALUE == 0 && (!vm.scheduleDFormData[i].Line_data[j+1].Line_data_occ[0].hasOwnProperty("ATTRIB_VALUE") ||
										(vm.scheduleDFormData[i].Line_data[j+1].Line_data_occ[0].hasOwnProperty("ATTRIB_VALUE") && (vm.scheduleDFormData[i].Line_data[j+1].Line_data_occ[0].ATTRIB_VALUE == null || vm.scheduleDFormData[i].Line_data[j+1].Line_data_occ[0].ATTRIB_VALUE == undefined)))){
									vm.scheduleDFormData[i].Line_data[j].Line_data_occ[cost_basis_index].HAS_ERROR = "Y";
									vm.scheduleDFormData[i].Line_data[j].Line_data_occ[cost_basis_index].ATTRIB_VALUE_ERROR = "When Cost or Other Basis amount is 0, an explanation comment must be entered!";
									returnValue = 1;
								}
								if(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[cost_basis_index].ATTRIB_VALUE < 0 ){
									vm.scheduleDFormData[i].Line_data[j].Line_data_occ[cost_basis_index].HAS_ERROR = "Y";
									vm.scheduleDFormData[i].Line_data[j].Line_data_occ[cost_basis_index].ATTRIB_VALUE_ERROR = "As the Amounts are in Increase/Decrease format, Cost Basis should not be less than zero!";
									returnValue = 1;
								}
								if(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[amt_adjusted].ATTRIB_VALUE != 0){
									if(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[code_instructions].ATTRIB_VALUE == null || ['B', 'T', 'N', 'H', 'D', 'Q', 'X', 'R', 'W', 'L', 'E', 'S', 'C', 'M', 'O', 'Z', 'Y'].indexOf(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[code_instructions].ATTRIB_VALUE) < 0){
										vm.scheduleDFormData[i].Line_data[j].Line_data_occ[code_instructions].HAS_ERROR = "Y";
										vm.scheduleDFormData[i].Line_data[j].Line_data_occ[code_instructions].ATTRIB_VALUE_ERROR = "Please select the Code from following list 'B', 'T', 'N', 'H', 'D', 'Q', 'X', 'R', 'W', 'L', 'E', 'S', 'C', 'M', 'O', 'Z', 'Y'";
										returnValue = 1;
									}
								}
							}
							
						}
					}
				}
			}
			return returnValue;
		}
		
		function getDiffDates(dateAcquired, dateSold){
			if(typeof dateAcquired === 'string'){
				dateAcquired = convertToDate(dateAcquired);
				dateSold = convertToDate(dateSold);
			}
			
			return ((dateSold -  dateAcquired)/ (1000 * 3600 * 24));
		}
		
		function convertToDate(str)
		{
		   var dateArr = str.split("/");
		   return new Date(dateArr[2], dateArr[0], dateArr[1])
		}
		
		function getUnallocatedAmt(){
			for(var i=0; i < vm.scheduleDFormData.length; i++){
				if(vm.scheduleDFormData[i].ROW_TYPE == 'TH'){
					if(vm.scheduleDFormData[i].Line_data[1].DESCRIPTION == 'Unallocated Amount' && vm.scheduleDFormData[i].Line_data[1].DATA_TYPE == 'number' && vm.scheduleDFormData[i].Line_data[1].ATTRIB_VALUE != null && vm.scheduleDFormData[i].Line_data[1].ATTRIB_VALUE != undefined){
						vm.unAllocatedAmt = parseInt(vm.scheduleDFormData[i].Line_data[1].ATTRIB_VALUE);
						console.log("the unallocatedamt was updated. And the unallocated amt is :::::", vm.unAllocatedAmt);
					}
				}
			}
		}
		
		vm.AddNewRow = function(line_no){
			console.log("The new line is created under :::::::", line_no);
			var occurrance = 1;
			var trans_details_key = null;
			for (var i=0; i < vm.scheduleDFormData.length; i++){
				if(vm.scheduleDFormData[i].ROW_TYPE == 'CH'){
					if(null != vm.scheduleDFormData[i].Line_data[vm.scheduleDFormData[i].Line_data.length -1].Occurrence && vm.scheduleDFormData[i].Line_data[vm.scheduleDFormData[i].Line_data.length -1].Occurrence != "" && vm.scheduleDFormData[i].Line_data[vm.scheduleDFormData[i].Line_data.length -1].Occurrence != undefined){
						occurrance = vm.scheduleDFormData[i].Line_data[vm.scheduleDFormData[i].Line_data.length -1].Occurrence + 1;
						trans_details_key = vm.scheduleDFormData[i].Line_data[vm.scheduleDFormData[i].Line_data.length -1].Trans_details_key;
					}
					var new_row = angular.copy(vm.scheduleDFormData[i].Line_data[0]);
					new_row.Line_Type = "NEW_EDIT_ROW";
					new_row.Occurrence = occurrance;
					new_row.Trans_details_key = trans_details_key;
					new_row.IS_EDITTED = "Y";
					if(new_row.Line_no == line_no){
						for (var j=0; j < new_row.Line_data_occ.length; j++){
							if(new_row.Line_data_occ[j].EDIT_TYPE == "custom_date_various" && new_row.Line_data_occ[j].DATA_TYPE == "dateVarious"){
								new_row.Line_data_occ[j].ATTRIB_VALUE = new Date();
							} else if (new_row.Line_data_occ[j].EDIT_TYPE == "currency" && new_row.Line_data_occ[j].DATA_TYPE == "number"){
								new_row.Line_data_occ[j].ATTRIB_VALUE = 0;
							} else if (new_row.Line_data_occ[j].EDIT_TYPE == "label" && new_row.Line_data_occ[j].DATA_TYPE == "string"){
							} else {	
								new_row.Line_data_occ[j].ATTRIB_VALUE = null;
							}
							new_row.Line_data_occ[j].OCCURRENCE = occurrance;
							new_row.Line_data_occ[j].TRANS_DETAILS_KEY = trans_details_key;
							new_row.Line_data_occ[j].IS_CHANGED_FLAG = "Y";
						}
						vm.scheduleDFormData[i].Line_data.push(new_row);
					}
					
					var new_row_comments = angular.copy(vm.scheduleDFormData[i].Line_data[0]);
					new_row_comments.Line_Type = "NEW_EDIT_COMMENT_ROW";
					new_row_comments.Occurrence = occurrance;
					new_row_comments.Trans_details_key = trans_details_key;
					new_row_comments.IS_EDITTED = "Y";
					if(new_row_comments.Line_no == line_no){
						new_row_comments.Line_data_occ = [new_row_comments.Line_data_occ[new_row_comments.Line_data_occ.length-1]];
						console.log(new_row_comments.Line_data_occ);
						new_row_comments.Line_data_occ[0].OCCURRENCE = occurrance;
						new_row_comments.Line_data_occ[0].ATTRIB_VALUE = null;
						new_row_comments.Line_data_occ[0].TRANS_DETAILS_KEY = trans_details_key;
						new_row_comments.Line_data_occ[0].IS_CHANGED_FLAG = "Y";
						vm.scheduleDFormData[i].Line_data.push(new_row_comments);
					}
				}
			}
			console.log(vm.scheduleDFormData);
		}
		
		vm.editExistingRecord = function(line_no, occurrence, index){
			//console.log("This was from the reportable Transaction service Factory, the rep trnascation data was :::::", ReportableTransactionFactory.finalRepTransaction);
			console.log("These are the line_no and occurrence for the editting rows", line_no, occurrence, index);
			for (var i=0; i < vm.scheduleDFormData.length; i++){
				if(vm.scheduleDFormData[i].ROW_TYPE == 'CH'){
					for(var j=0; j < vm.scheduleDFormData[i].Line_data.length; j++){
						if(j == index || j == index+1){
							if(vm.scheduleDFormData[i].Line_data[j].Line_Type == "DATA_ROW" && vm.scheduleDFormData[i].Line_data[j].Line_no == line_no && vm.scheduleDFormData[i].Line_data[j].Occurrence == occurrence){
								vm.scheduleDFormData[i].Line_data[j].Line_Type = "NEW_EDIT_ROW";
								for(var l=0; l < vm.scheduleDFormData[i].Line_data[j].Line_data_occ.length; l++){
									if(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].EDIT_TYPE == "currency" && vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].DATA_TYPE == "number"){
										vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = parseInt(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE);
									} else if (vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].EDIT_TYPE == "custom_date_various" && vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].DATA_TYPE == "dateVarious"){
										if (vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE != "VARIOUS"){
											vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = new Date(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE);
										}
									}
									//vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].IS_CHANGED_FLAG = "Y";
								}
							} else if (vm.scheduleDFormData[i].Line_data[j].Line_Type == "DATA_COMMENTS" && vm.scheduleDFormData[i].Line_data[j].Line_no == line_no && vm.scheduleDFormData[i].Line_data[j].Occurrence == occurrence){
								vm.scheduleDFormData[i].Line_data[j].Line_Type = "NEW_EDIT_COMMENT_ROW";
								//vm.scheduleDFormData[i].Line_data[j].Line_data_occ[0].IS_CHANGED_FLAG = "Y";
							}
						}
					}
					
				}
			}
			console.log(vm.scheduleDFormData);
		}
		
		vm.verifyLossTransaction = function(attrib_short_code, mainIndex, parentIndex, attrib_value, occurrence, trans_details_key){
			console.log("Clicked on the transaction for the checkbox", attrib_short_code, mainIndex, parentIndex, attrib_value, occurrence, trans_details_key);
			var short_desc = attrib_short_code.substring(0, attrib_short_code.lastIndexOf("_"));
			var line_no = attrib_short_code.substring(attrib_short_code.lastIndexOf("_")+1, attrib_short_code.length);
			if(short_desc != null && short_desc == "LOSS_TRANSACTION" && (attrib_value == "N" || attrib_value == "NO")){
				var success_message = "Reportable Transaction was succesfully removed!!";
				var message = "Please save the transaction to update the Loss Transaction Column";
				FSchddFactory.deleteRepTransaction(rowData, trans_details_key, occurrence, line_no, filterParams.scenario).then(function (data){
					if(null != data.data.errorMessage && data.data.errorMessage != "null"){
						console.log("There was the error message and the message is ", data.data.errorMessage);
						AlertService.add("error", data.data.errorMessage, 4000);
					}else {
						console.log("There was no error message and the message was ", data.data.errorMessage);
						AlertService.add("success", success_message, 4000);
						AlertService.add("info", message, 20000);
					}
				});
			}
		}
		
		vm.lossTransaction = function(attrib_short_code, mainIndex, parentIndex, attrib_value, occurrence){
			console.log("This was from the reportable Transaction service Factory, the rep trnascation data was :::::", ReportableTransactionFactory.finalRepTransaction);
			console.log("Clicked on the transaction for the checkbox", attrib_short_code, mainIndex, parentIndex, attrib_value, occurrence);
			var short_desc = attrib_short_code.substring(0, attrib_short_code.lastIndexOf("_"));
			var line_no = attrib_short_code.substring(attrib_short_code.lastIndexOf("_")+1, attrib_short_code.length);
			var repData = [];
			console.log("short_desc and the line_no for the checkbox are ::::::::::::", short_desc, line_no);
			if(null != ReportableTransactionFactory.finalRepTransaction && ReportableTransactionFactory.finalRepTransaction.length > 0){
				if(ReportableTransactionFactory.finalRepTransaction[0].SCHDD_LINE_NO == line_no && ReportableTransactionFactory.finalRepTransaction[0].OCCURRENCE == occurrence){
					repData = angular.copy(ReportableTransactionFactory.finalRepTransaction);
				}
			}
			if(short_desc != null && short_desc == "LOSS_TRANSACTION" && (attrib_value == "Y" || attrib_value == "YES")){
				$uibModal.open({
		            templateUrl: 'app/components/Forms/RepTransaction/ReportableTransactionModal.html',
		            controller: 'ReportableTransactionCtrl as ctrl',
		            size: 'md',
		            backdrop: 'static',
		            resolve: {
		            	dataRow: function (){
		            		return vm.scheduleDFormData[mainIndex].Line_data[parentIndex];
		            	},
						rowData: function (){
							return vm.rowData;
						},
		            	repTransaction: function(){
		            		return repData;
		            	},
						isValidScenario: function(){
							return vm.isValidScenario;
						},		            	
		            }
		        });
			}
		}
		
		vm.removeExistingRecord = function(line_no, Occurrence, index){
			console.log("These are the line_no and occurrence for the deleting rows", line_no, Occurrence, index);
			for (var i=0; i < vm.scheduleDFormData.length; i++){
				if(vm.scheduleDFormData[i].ROW_TYPE == 'CH'){
					for(var j=0; j < vm.scheduleDFormData[i].Line_data.length; j++){
						if(j == index){
							if((vm.scheduleDFormData[i].Line_data[j].Line_Type == "DATA_ROW" || vm.scheduleDFormData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW") && vm.scheduleDFormData[i].Line_data[j].Line_no == line_no && null != vm.scheduleDFormData[i].Line_data[j].Occurrence && vm.scheduleDFormData[i].Line_data[j].Occurrence != "" && vm.scheduleDFormData[i].Line_data[j].Occurrence == Occurrence){
								vm.scheduleDFormData[i].DELETE_ROWS.push(vm.scheduleDFormData[i].Line_data[j]);
								vm.scheduleDFormData[i].Line_data.splice(j,2);
							}else if((vm.scheduleDFormData[i].Line_data[j].Line_Type == "DATA_ROW" || vm.scheduleDFormData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW") && vm.scheduleDFormData[i].Line_data[j].Line_no == line_no && (Occurrence == null || Occurrence == "")){
								vm.scheduleDFormData[i].Line_data.splice(j,2);
							}
						}
					}
				}
			}
			vm.calcFinalAmounts();
			console.log(vm.scheduleDFormData);
		}
		
		vm.checkEditScenario = function (s_type_code, t_scenario, t_issue){        	
        	if(vm.scenario == t_scenario && ((s_type_code == 'EXAM' && vm.glbl_issue_id == t_issue) || s_type_code != 'EXAM')){
        		return true;
        	}        	
        	return false;        	
        }
		
		vm.saveNewEdittedRecord = function(line_no, occurrence, index){
			console.log("These are the line_no and occurrence that are newly added or editted", line_no, occurrence, index);
			for (var i=0; i < vm.scheduleDFormData.length; i++){
				if(vm.scheduleDFormData[i].ROW_TYPE == 'CH'){
					for(var j=0; j < vm.scheduleDFormData[i].Line_data.length; j++){
						if(j== index || j== index + 1){
							if(vm.scheduleDFormData[i].Line_data[j].Line_Type == "NEW_EDIT_ROW" && vm.scheduleDFormData[i].Line_data[j].Line_no == line_no && vm.scheduleDFormData[i].Line_data[j].Occurrence == occurrence){
								vm.scheduleDFormData[i].Line_data[j].Line_Type = "DATA_ROW";
							}else if(vm.scheduleDFormData[i].Line_data[j].Line_Type == "NEW_EDIT_COMMENT_ROW" && vm.scheduleDFormData[i].Line_data[j].Line_no == line_no && vm.scheduleDFormData[i].Line_data[j].Occurrence == occurrence){
								vm.scheduleDFormData[i].Line_data[j].Line_Type = "DATA_COMMENTS";
							}
						}
						if(j== index && vm.scheduleDFormData[i].Line_data[j].Line_no == line_no && vm.scheduleDFormData[i].Line_data[j].Occurrence == occurrence){
							for(var l=0; l < vm.scheduleDFormData[i].Line_data[j].Line_data_occ.length; l++){
								if(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].IS_EDITABLE_CELL == "Y" && vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].DATA_TYPE == "dateVarious" && vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].EDIT_TYPE == "custom_date_various"){
									vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = getDateFormat(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE);
								} else if(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].IS_EDITABLE_CELL == "Y" && vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].DATA_TYPE == "string" && vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].EDIT_TYPE == "radio"){
									if(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE == null ||vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE == undefined){
										vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = "Temp";
									}
								}else if(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].IS_EDITABLE_CELL == "Y" && vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].DATA_TYPE == "string" && vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].EDIT_TYPE == "checkbox"){
									if(vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE == null ||vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE == undefined){
										vm.scheduleDFormData[i].Line_data[j].Line_data_occ[l].ATTRIB_VALUE = "N";
									}
								}
							}
						}
					}
				}
			}
			console.log(vm.scheduleDFormData);
		}
		
		vm.valuechanged = function(parent_index, occurrence, line_no, index, row_type){
			console.log("These are the parent_index, occurrence and index of the editted field", parent_index, occurrence, line_no, index, row_type);
			for (var i=0; i < vm.scheduleDFormData.length; i++){
				if(vm.scheduleDFormData[i].ROW_TYPE == 'CH' && vm.scheduleDFormData[i].ROW_TYPE == row_type){
					if(vm.scheduleDFormData[i].Line_data[parent_index] != null && vm.scheduleDFormData[i].Line_data[parent_index] != undefined && vm.scheduleDFormData[i].Line_data[parent_index].Line_no == line_no){
						if(vm.scheduleDFormData[i].Line_data[parent_index].Line_Type == "NEW_EDIT_COMMENT_ROW" && vm.scheduleDFormData[i].Line_data[parent_index].Line_no == line_no && vm.scheduleDFormData[i].Line_data[parent_index].Occurrence == occurrence){
							vm.scheduleDFormData[i].Line_data[parent_index].Line_data_occ[0].IS_CHANGED_FLAG = "Y";
						}else {
							if(vm.scheduleDFormData[i].Line_data[parent_index].Line_no == line_no && vm.scheduleDFormData[i].Line_data[parent_index].Occurrence == occurrence){
								vm.scheduleDFormData[i].Line_data[parent_index].Line_data_occ[index+1].IS_CHANGED_FLAG = "Y";
								vm.scheduleDFormData[i].Line_data[parent_index].Line_data_occ[index+1].HAS_ERROR = "N";
							}
						}
					}
				} else if(vm.scheduleDFormData[i].ROW_TYPE == 'DT' && vm.scheduleDFormData[i].ROW_TYPE == row_type){
					if(i == parent_index && vm.scheduleDFormData[i].Line_data[index].LINE_NO == line_no){
						vm.scheduleDFormData[i].Line_data[index].IS_CHANGED_FLAG = "Y";
					}
				}
			}
			console.log(vm.scheduleDFormData);
		}
		
		vm.updateAmount = function(index, line_no){
			console.log("We are updating the amounts for this column:::::: ", index);
			for (var i=0; i < vm.scheduleDFormData.length; i++){
				if(vm.scheduleDFormData[i].ROW_TYPE == 'CH' && vm.scheduleDFormData[i].Line_data[0].Line_no == line_no){
					for(var j=0; j < vm.scheduleDFormData[i].Line_data[index].Line_data_occ.length; j++ ){
						if(vm.scheduleDFormData[i].Line_data[index].Line_data_occ[j].ATTRIB_CALC_FORMULA != null){
							var line_data_values = vm.scheduleDFormData[i].Line_data[index].Line_data_occ;
							vm.scheduleDFormData[i].Line_data[index].Line_data_occ[j].ATTRIB_VALUE = vm.calcLineFormula(vm.scheduleDFormData[i].Line_data[index].Line_data_occ[j].ATTRIB_CALC_FORMULA, line_data_values);
							vm.scheduleDFormData[i].Line_data[index].Line_data_occ[j].IS_CHANGED_FLAG = "Y";
						}
					}
				}
			}
			vm.calcFinalAmounts();
		}
		
		vm.calcLineFormula = function(formula, line_data_values){
			console.log("Given formula and index of the row are ", formula, line_data_values);
			var lineFormulaArray = formula.split(/[+-/\\*\\]/);
			for(var i=0; i<lineFormulaArray.length; i++){
				if(lineFormulaArray[i].indexOf("(") > -1 && lineFormulaArray[i].indexOf(")") > -1){
					lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
					var currentTabAtrribVal = $filter("filter")(line_data_values, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
					var lineAmt = parseInt(currentTabAtrribVal[0].ATTRIB_VALUE);
					if(parseInt(lineAmt)> 0){
						formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
					}else{
						formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
					}
				}
			}
			
			console.log("Given formula and index of the row are ", formula, line_data_values);
			if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){
				console.log(eval(formula));
				return eval(formula);
			}else return 0;
		}
		
		//console.log(vm.calcFormula((SALE_PRICE_1)-(COST_BASIS_1), 2));
		vm.calcFinalAmounts = function(){
			for (var i=0; i < vm.scheduleDFormData.length; i++){
				if(vm.scheduleDFormData[i].ROW_TYPE == 'TH'){
					for(var j=0; j < vm.scheduleDFormData[i].Line_data.length; j++){
						if(vm.scheduleDFormData[i].Line_data[j].ATTRIB_CALC_FORMULA != null){
							vm.scheduleDFormData[i].Line_data[j].ATTRIB_VALUE = vm.calc_final_amt(vm.scheduleDFormData[i].Line_data[j].ATTRIB_CALC_FORMULA);
							vm.scheduleDFormData[i].Line_data[j].IS_CHANGED_FLAG = "Y";
						}
					}
				}
			}
			getUnallocatedAmt();
		}
		
		vm.calc_final_amt = function(formula){	
			var lineFormulaArray = formula.split(/[+-/\\*\\]/);
			for(var i=0; i<lineFormulaArray.length; i++){
				var lineAmt = 0;
				if(lineFormulaArray[i].indexOf("(") > -1 && lineFormulaArray[i].indexOf(")") > -1){
					lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
					for(var k=0; k < vm.scheduleDFormData.length; k++){
						if(vm.scheduleDFormData[k].ROW_TYPE == 'CH'){
							for(var j=0; j < vm.scheduleDFormData[k].Line_data.length; j++){
								if(vm.scheduleDFormData[k].Line_data[j].Line_Type == "DATA_ROW" || vm.scheduleDFormData[k].Line_data[j].Line_Type == "NEW_EDIT_ROW"){
									var row_data = vm.scheduleDFormData[k].Line_data[j].Line_data_occ;
									var currentTabAtrribVal = $filter("filter")(row_data, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
									if(null != currentTabAtrribVal && currentTabAtrribVal.length > 0){
										lineAmt = lineAmt + parseInt(currentTabAtrribVal[0].ATTRIB_VALUE);
									}
								}
							}
						} else {
							var row_data = vm.scheduleDFormData[k].Line_data;
							var currentTabAtrribVal = $filter("filter")(row_data, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
							if(null != currentTabAtrribVal && currentTabAtrribVal.length > 0){
								lineAmt = lineAmt + parseInt(currentTabAtrribVal[0].ATTRIB_VALUE);
							}
						}
					}
					//console.log("Formula before Replacing with amount========", formula);
					if(parseInt(lineAmt)> 0){
						formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
					}else{
						formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
					}
				}
			}
			
			//console.log("Given formula and index of the row are ", formula);
			if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){
				console.log(eval(formula));
				return eval(formula);
			}else return 0;
		}
	}
});