define([
    'angular'

], function () {
    'use strict';

    var controllers = angular.module('app.FormScheduleK2Ctrl', [])
        .controller('F1065ScheduleK2Controller', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse', '$scope', '$rootScope', '$timeout', 'rowData', 'colData', '$filter','workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG', 'USER_SETTINGS', F1065ScheduleK2Controller]);

        
    function F1065ScheduleK2Controller(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, rowData, colData, $filter, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS) {
        var vm = this;
        
        // modalTitle shouldn't be hardcoded.
        vm.modalTitle = "Schedule K- Partners’ Distributive Share Items";
        vm.isSaving = false;
        vm.canSave = true ;
        
        vm.formData = {
            "addrows": [],
            "editrows": [],
            "removerows": []
        };
        $scope.entityList = {
            "Tax Year": rowData.TAX_YEAR,
            "LEID": rowData.LEID,
            "LE Name": rowData.LE_NAME            
        };
        vm.subtableString = "Loading ...";
        var saveParamDtls = [];
        var tempDataObj = {};
        $scope.scheduleFFormView = {rows: []};
        vm.showFormLoader = true;
        vm.tax_year = GlobalService.globalParams.tax_year;
        vm.optionsList = [];
    
            if(GlobalService.globalParams.tax_year < "2023"){
                vm.optionsList = ["Code C. Fuel tax credit information.", 
                    "Code D. Qualified rehabilitation expenditures (other than rental real estate).", 
                    "Code D1. Rehabilitation credit (Sec 47) - other than rental real estate", 
                    "Code E. Basis of energy property.", 
                    "Code F. Recap Low Inc Hous. Credit 42(J)(5)", 
                    "Code G. Recap Low-Inc Hous. Credit (Other)", 
                    "Code H. Recapture of investment credit.", 
                    "Code I. Recapture of other credits.", 
                    "Code J. Look-back interest—completed long-term contracts.", 
                    "Code K. Look-back interest—income forecast method.", 
                    "Code L. Dispositions of property with section 179 deductions.", 
                    "Code M. Recapture of section 179 deduction.", 
                    "Code N. Business interest expense", 
                    "Code O. Section 453(l)(3) information.", 
                    "Code P. Section 453A(c) information.", 
                    "Code Q. Section 1260(b) information.", 
                    "Code R. Interest allocable to production expenditures.", 
                    "Code S. Capital construction fund (CCF) nonqualified withdrawals.", 
                    "Code T. Depletion information—oil and gas.", 
                    "Code U. Section 743(b) basis adjustment.",
                    "Code V. Unrelated business taxable income.", 
                    "Code W. Precontribution gain (loss).", 
                    "Code Y. Net investment income.", 
                    "Codes Z. Section 199A information.", 
                    "Code AA. Section 704(c) information",
                    "Code AB. Section 751 gain (loss).", 
                    "Code AC. Section 1(h)(5) gain (loss).", 
                    "Code AD. Deemed section 1250 unrecaptured gain.", 
                    "Code AE. Excess taxable income.", 
                    "Code AF. Excess business interest income.", 
                    "Code AG. Gross receipts for section 448(c)", 
                    "Code AH. Other information. ", 
                    "20AH1. Section 743(b) negative adjustments - Goodwill Amortization", 
                    "20AH2. Section 743(b) negative adjustments - Lifo Adjustments",
                    "Z1. Section 199A Qualified Business Income",
                    "Z2. Section 199A W-2 Wages",
                    "Z3. Section 199A Unadjusted Basis",
                    "Z4. Section 199A REIT Dividends",
                    "Z5. Section 199A PTP Income"];
            }else if (GlobalService.globalParams.tax_year == "2023"){
                vm.optionsList = [
                    "Code C. Fuel tax credit information.", 
                    "Code D. Qualified rehabilitation expenditures (other than rental real estate).", 
                    "Code D1. Rehabilitation credit (Sec 47) - other than rental real estate", 
                    "Code E. Basis of energy property.", 
                    "Code F. Recap Low Inc Hous. Credit 42(J)(5)", 
                    "Code G. Recap Low-Inc Hous. Credit (Other)", 
                    "Code H. Recapture of investment credit.", 
                    "Code I. Recapture of other credits.", 
                    "Code J. Look-back interest—completed long-term contracts.", 
                    "Code K. Look-back interest—income forecast method.", 
                    "Code L. Dispositions of property with section 179 deductions.", 
                    "Code M. Recapture of section 179 deduction.", 
                    "Code N. Business interest expense", 
                    "Code O. Section 453(l)(3) information.", 
                    "Code P. Section 453A(c) information.", 
                    "Code Q. Section 1260(b) information.", 
                    "Code R. Interest allocable to production expenditures.", 
                    "Code S. Capital construction fund (CCF) nonqualified withdrawals.", 
                    "Code T. Depletion information—oil and gas.", 
                    "Code U. Section 743(b) basis adjustment.",
                    "Code V. Unrelated business taxable income.", 
                    "Code W. Precontribution gain (loss).", 
                    "Code X. Payment obligations including guarantees and deficit restoration obligations (DROs)",
                    "Code Y. Net investment income.", 
                    "Codes Z. Section 199A information.", 
                    "Code AA. Section 704(c) information",
                    "Code AB. Section 751 gain (loss).", 
                    "Code AC. Section 1(h)(5) gain (loss).", 
                    "Code AD. Deemed section 1250 unrecaptured gain.", 
                    "Code AE. Excess taxable income.", 
                    "Code AF. Excess business interest income.", 
                    "Code AG. Gross receipts for section 448(c)", 
                    "Code AH. Other information. ", 
                    "20AH1. Section 743(b) negative adjustments - Goodwill Amortization", 
                    "20AH2. Section 743(b) negative adjustments - Lifo Adjustments",
                    "Z1. Section 199A Qualified Business Income",
                    "Z2. Section 199A W-2 Wages",
                    "Z3. Section 199A Unadjusted Basis",
                    "Z4. Section 199A REIT Dividends",
                    "Z5. Section 199A PTP Income",
                    "Code AH3. Noncash charitable contributions",
                    "Code AI. Interest and tax on deferred compensation to partners",
                    "Code AJ. Excess business loss limitation",
                    "Code AK. Gain from mark-to-market election",
                    "Code AL. Section 721(c) partnership",
                    "Code AM. Section 1061 information",
                    "Code AN. Farming and fishing business",
                    "Code AO. PTP information",
                    "Code AP. Inversion gain",
                    "Code AQ. Conservation reserve program payments",
                    "Code AR. IRA disclosure",
                    "Code AS. Qualifying advanced coal project property and qualifying gasification project property",
                    "Code AT. Qualifying advanced energy project property",
                    "Code AU. Advanced manufacturing investment property",
                    "Code AV. Reserved for future usev",
                    "Code AW. Reportable transactions",
                    "Code AY. Foreign partners, Form 8990, Schedule A",
                    "Code ZZ. Other",
                ]
            }
            else if(GlobalService.globalParams.tax_year == "2024")
                {
                    var params = { 
                "action_code":"qd5skt",
                "sso_id":"400523221",
                "tax_year": vm.tax_year
                };
    
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params)
            .then(function (response) {
                vm.optionsList = Object.keys(response.jsonObject).map(key => {
                    return response.jsonObject[key].SUB_ACCT;
                });
            });
            }

        vm.scenarioLabel = $filter("filter")(workspaceFactory.activeScreen.filters.filters,{param_name:'scenario'})[0].selected.LABEL;

        vm.title = vm.modalTitle + ' ( Tax Year : ' + rowData.TAX_YEAR + ' - Scenario : ' + vm.scenarioLabel + ' )'; 

        if(typeof rowData.ISSUE_DESC !== 'undefined') {
            vm.ISSUE_DESC =  rowData.ISSUE_DESC;
            
        }
        else
            vm.ISSUE_DESC = '';

        function fetchSchCrecords() {
            var params = { 
                "action_id": 31259, 
                "tax_year": rowData.TAX_YEAR, 
                "scenario": rowData.SCENARIO_KEY, 
                "jcd_key": rowData.JCD_KEY, /*"group_obj_key": 201473*/ 
                "combination_key": rowData.COMBINATION_KEY, 
                "schedule_name": "K"};
            
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadPivotFormJson', params).then(function (data) {
                // vm.modalTitle = data.irsForm.formItemList[0].dataRowList[0].dataCellList[1].name; // get the title from service
               for (var i = 2; i < data.irsForm.formItemList.length; i++) {
                    saveParamDtls = [];
                    tempDataObj = {};
                    saveParamDtls = data.irsForm.formItemList[i].dataRowList[0].rowDimensions.object_id.split('~');
                    tempDataObj.saveParams = saveParamDtls;                   

                    for (var j = 0; j < data.irsForm.formItemList[i].dataRowList[0].dataCellList.length; j++) {
                        if (j == 0) {
                            tempDataObj.num = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].name;
                        } else if (j == 1) {
                            tempDataObj.description = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].name;
                        } else if (j == 2) {
                            tempDataObj.lineAttrKeyA = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[1];
                            tempDataObj.attrib_name_a = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[0];
                            tempDataObj.attrib_order_a = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[2];
                            if(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].property.is_editable_cell == 'Y'){
								tempDataObj.is_editable = false;
							}else {
								tempDataObj.is_editable = true;
                            }
                            if (data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == null ||
                                data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == "") {
                                if(tempDataObj.num == "6a" || tempDataObj.num == "6b" || tempDataObj.num == "6c" || tempDataObj.num == "9a" || tempDataObj.num == "9b" || tempDataObj.num == "9c"){
                                    tempDataObj.value1 = 0;
                                }else{
                                    tempDataObj.value1 = "";
                                }
                                
                            }else{
                                tempDataObj.value1 = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value;
                            }
                            if(tempDataObj.num == "16p(1)" || tempDataObj.num == "16p(2)"){
                                tempDataObj.value1 = (tempDataObj.value1 == "true");
                            }
                            
                            
                        } else {
                            tempDataObj.lineAttrKeyB = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[1];
                            tempDataObj.attrib_name_b = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[0];
                            tempDataObj.attrib_order_b = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].key.split('~')[2];
                            if(data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].property.is_editable_cell == 'Y'){
								tempDataObj.is_editable = false;
							}else {
								tempDataObj.is_editable = true;
                            }
                            if (data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == null ||
                                data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value == "") {
                                if(tempDataObj.num == "6a" || tempDataObj.num == "6b" || tempDataObj.num == "6c" || tempDataObj.num == "9a" || tempDataObj.num == "9b" || tempDataObj.num == "9c"){
                                    tempDataObj.value2 = 0;
                                }else{
                                    tempDataObj.value2 = "";
                                }
                                
                            }else{
                                tempDataObj.value2 = data.irsForm.formItemList[i].dataRowList[0].dataCellList[j].value;
                            }
                            if(tempDataObj.num == "16p(1)" || tempDataObj.num == "16p(2)"){
                                tempDataObj.value2 = (tempDataObj.value2 == "true");
                            }
                            
                        }
                    }
                    tempDataObj.line_order = saveParamDtls[8];
                    $scope.scheduleFFormView.rows.push(tempDataObj);
                }
                
                vm.formListOriginal = angular.copy($scope.scheduleFFormView);
                $scope.scheduleFFormView.rows.forEach((row, index) => {
                    if( row.num == '20c'){
                        $scope.line20CNumber = index;
                    }
                    if( row.num == '20c1'){
                        $scope.line20C1Number = index;
                    }

                })
                $scope.scheduleFFormView.rows[$scope.line20CNumber].JSON_DATA = [];
                fetchSubTableRecords();
                initFormView($scope.scheduleFFormView);
                vm.showFormLoader = false;
            });
        }

        function fetchSubTableRecords(){
            vm.subtableString = "Loading ...";
            var params = {
            	combination_key: rowData.COMBINATION_KEY,
            	schedule: "K"
            };
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=z57kmr', params).then(function (data) {
                vm.subTable20c = [];
				angular.forEach(data.jsonObject, function (row, key) {
			      row.DATA_ATTRIB_B = parseInt(row.DATA_ATTRIB_B);
                  vm.subTable20c.push(row);
                
                });
                $scope.scheduleFFormView.rows[$scope.line20CNumber].JSON_DATA = vm.subTable20c;
                if(vm.subTable20c.length === 0) {
                    vm.subtableString = "No Records."
                }
                vm.formListOriginal = angular.copy($scope.scheduleFFormView);
            });
        };

        fetchSchCrecords();
        
        
        vm.validateValues = function(){
            let hasError = false;

            if($scope.scheduleFFormView.rows[9].value1  < 0 ){
                hasError = true;
                AlertService.add("error", "Value of line 6b can't be less than 0" );
            }
            if($scope.scheduleFFormView.rows[10].value1  < 0 ){
                hasError = true;
                AlertService.add("error", "Value of line 6c can't be less than 0" );
            }

            if($scope.scheduleFFormView.rows[8].value1 !== "" &&
            $scope.scheduleFFormView.rows[9].value1 !== "" &&
            $scope.scheduleFFormView.rows[10].value1 !== "" ){
                if(parseInt($scope.scheduleFFormView.rows[8].value1)  < (parseInt($scope.scheduleFFormView.rows[9].value1) + parseInt($scope.scheduleFFormView.rows[10].value1)) ){
                    hasError = true;
                    AlertService.add("error", "Amount on 6a should be greater than or equal to Sum of 6b and 6c" );
                }
            }
            if($scope.scheduleFFormView.rows[13].value1 !== "" &&
            $scope.scheduleFFormView.rows[14].value1 !== "" &&
            $scope.scheduleFFormView.rows[15].value1 !== "" ){
                if(Math.abs(parseInt($scope.scheduleFFormView.rows[13].value1))  < Math.abs((parseInt($scope.scheduleFFormView.rows[14].value1) + parseInt($scope.scheduleFFormView.rows[15].value1))) ){
                    hasError = true;
                    AlertService.add("error", "Amount on 9a should be greater than or equal to Sum of 9b and 9c" );
                }
            }

            if(hasError){
                vm.canSave = false;
            }else{
                vm.canSave = true;
            }
        }

        vm.addRows = function(type, i){
			var newRow = {
				OCCURRENCE : $scope.scheduleFFormView.rows[i].JSON_DATA.length + 1,
				DATA_ATTRIB_A : "",
				DATA_ATTRIB_B : ""				
			};
			
			$scope.scheduleFFormView.rows[i].JSON_DATA.push(newRow);
		};
        vm.removeSubtableRow = function(index){
            $scope.scheduleFFormView.rows[$scope.line20CNumber].JSON_DATA.splice(index, 1);
            if($scope.scheduleFFormView.rows[$scope.line20CNumber].JSON_DATA.length ===0){
                vm.subtableString = "No Records";
            }
        }
        vm.unselectOther = function(index,type){
			if(type == 'Paid'){
				$scope.scheduleFFormView.rows[index+1].value1 = false;
			}else {
				$scope.scheduleFFormView.rows[index-1].value1 = false;
			}
		};

        vm.setValue = function(index,type){
            if(type == '1'){
                $scope.scheduleFFormView.rows[index].value1 = '1';
            }else {
                $scope.scheduleFFormView.rows[index].value1 = '0';
            }
        };

        vm.saveSchddForm = function (url, data) {
            var jsonSettings = {
                "tax_year": rowData.TAX_YEAR,
                "scenario": rowData.SCENARIO_KEY,
                "jcd_key": rowData.JCD_KEY, 
                "combination_key": rowData.COMBINATION_KEY, 
                "trans_type_key": data[0].editrows[0][0].trans_type_key
            };
            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            var jsonSettings = JSON.stringify(jsonSettings);
            var params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                jsonObj: jsonObj
            }, params);
            params = _.extend({
                jsonSettings: jsonSettings
            }, params);
            params.process_name = workspaceFactory.activeScreen.label;
            params.combination_key = rowData.COMBINATION_KEY;
            params.trans_type_key = data[0].editrows[0][0].trans_type_key;
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                vm.isSaving =false;
                var status = response.status;
                
                if (response.data.callSuccess == "1" && response.data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    var args = {
                        combination_key: rowData.COMBINATION_KEY,
                        gridFilter: {
                            combination_key: rowData.COMBINATION_KEY
                        }
                    };
                    $rootScope.$emit('gridUpdate', args);
                    AlertService.add("success", "Transactions saved successfully", 4000);
                } else {
                    AlertService.add("", response.data.errorMessage);
                }
                return response.data;
            });
            console.log("------------At the end-----------", promise);
            return promise;
        };

        vm.save = function () {
            var formRowDataA = {};
            var formRowDataB = {};
            var tempArray = [];
            vm.validateValues();
            if(!vm.canSave){
                return ;
            }
            vm.isSaving = true;
            for (var i = 0; i < $scope.scheduleFFormView.rows.length; i++) {
                if($scope.scheduleFFormView.rows[i].num != '6a' 
                   && $scope.scheduleFFormView.rows[i].num != '9a'
                   && $scope.scheduleFFormView.rows[i].num != '13c'
                   && $scope.scheduleFFormView.rows[i].num != '13d'
                   && $scope.scheduleFFormView.rows[i].num != '16p'
                ){
                    formRowDataA = {
                        tax_year: rowData.TAX_YEAR,
                        trans_type_key: $scope.scheduleFFormView.rows[i].saveParams[1],
                        line_order: $scope.scheduleFFormView.rows[i].line_order,
                        attrib_order: $scope.scheduleFFormView.rows[i].attrib_order_a,
                        line_description: $scope.scheduleFFormView.rows[i].description,
                        combination_key: rowData.COMBINATION_KEY,
                        group_obj_key: rowData.GROUP_OBJ_KEY,
                        form_key: $scope.scheduleFFormView.rows[i].saveParams[2],
                        line_no: $scope.scheduleFFormView.rows[i].num,
                        occurence: $scope.scheduleFFormView.rows[i].saveParams[7],
                        line_attrib_key: $scope.scheduleFFormView.rows[i].lineAttrKeyA,
                        Attribute_Name: $scope.scheduleFFormView.rows[i].attrib_name_a,
                        Attribute_Value: ""+$scope.scheduleFFormView.rows[i].value1,
                        trans_dtls_key: $scope.scheduleFFormView.rows[i].saveParams[3],
                        trans_status: $scope.scheduleFFormView.rows[i].saveParams[6],
                        is_dirty: ''
                        
                    };
                    if(i==$scope.line20CNumber){
                        var attributes = ['A', 'B'];
                        let tempDataArray = [];
                        formRowDataA.JSON_DATA = [];
                        let index = 1;
                        angular.forEach($scope.scheduleFFormView.rows[$scope.line20CNumber].JSON_DATA, function (row, rowKey) {
                            var hasData = false;
                            tempDataArray = [];
                            angular.forEach(attributes, function (value, colKey) {
                                var tempObj = {
                                    OCCURRENCE : index,
                                    ATTRIB_NAME : "DATA_ATTRIB_" + value,
                                    ATTRIB_VALUE : row["DATA_ATTRIB_" + value]
                                }
                                if(tempObj.ATTRIB_VALUE !== undefined && tempObj.ATTRIB_VALUE !== ""){
                                    hasData = true;
                                }
                                tempDataArray.push(tempObj);                                
                            });
                            index++;
                            if(hasData){
                                angular.forEach(tempDataArray, function (column, colKey) {
                                    formRowDataA.JSON_DATA.push(column);
                                });
                            }
                        });
                        
                    }
                    tempArray.push(formRowDataA);
                    
                    if(i==$scope.line20CNumber){    
                        formRowDataB = {
                            tax_year: rowData.TAX_YEAR,
                            trans_type_key: $scope.scheduleFFormView.rows[i].saveParams[1],
                            line_order: $scope.scheduleFFormView.rows[i].line_order,
                            attrib_order: $scope.scheduleFFormView.rows[i].attrib_order_b,
                            line_description: $scope.scheduleFFormView.rows[i].description,
                            combination_key: rowData.COMBINATION_KEY,
                            group_obj_key: rowData.GROUP_OBJ_KEY,
                            form_key: $scope.scheduleFFormView.rows[i].saveParams[2],
                            line_no: $scope.scheduleFFormView.rows[i].num,
                            occurence: $scope.scheduleFFormView.rows[i].saveParams[7],
                            line_attrib_key: $scope.scheduleFFormView.rows[i].lineAttrKeyB,
                            Attribute_Name: $scope.scheduleFFormView.rows[i].attrib_name_b,
                            Attribute_Value: $scope.scheduleFFormView.rows[i].value2,
                            trans_dtls_key: $scope.scheduleFFormView.rows[i].saveParams[3],
                            trans_status: $scope.scheduleFFormView.rows[i].saveParams[6],
                            is_dirty: ''
                        };
                        tempArray.push(formRowDataB);    
                    }
                }
            }
            let line20C_JSON_DATA = [];
            tempArray.forEach((row, index) => {
                if(row.line_no== "20c" && row.JSON_DATA && row.JSON_DATA.length>0){
                    line20C_JSON_DATA = row.JSON_DATA  
                }
                if(row.line_no== "20c1"){
                    row.JSON_DATA = line20C_JSON_DATA;
                }
            });
            vm.formData.editrows.push(tempArray);
            vm.mainData = [];
            vm.mainData.push(vm.formData);
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=tkgaam";
            var saveDiagURL = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=ftmltp";
            vm.saveSchddForm(url, vm.mainData);
            var diagData = [];
            angular.forEach($scope.unallocatedAmount, function (array, key) {
                angular.forEach(array, function (obj, key) {
                    if(key === 3){
                        diagData.push(obj);
                    }
                });
            });
            
            $rootScope.$broadcast("gridRefresh", true);

            $timeout(function () {
                $scope.$broadcast("showLoader", true);
            }, 100);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
        vm.reset = function () {
            $scope.scheduleFFormView = angular.copy(vm.formListOriginal);
		}
        
        function initFormView(filteredFormData) {
            $scope.scheduleFFormView = filteredFormData;
        }

        //Dynamic Tabset Code
        vm.tabs = [
            {id: 1, name: "Form Entry", url: "app/components/partnership/F1065-Sch-K2/F1065-scheduleK2Entry.html", active: true },
            // {id: 2, name: "Form View", url: "app/components/Forms/F1120L-SCH-F/Form-scheduleFView.html", active: false},
            // {id: 3, name: "Audit", url: "app/components/Forms/F1120L-SCH-F/Form-scheduleFVersion.html", active: false},
            // {id: 4, name: "PDF", url: "app/components/Forms/F1120L-SCH-F/Form-scheduleFPdf.html", active: false}
        ];
    }
});
