define(["angular"], function () {
    "use strict";
    angular
        .module("app.EfileWorkflowControllers", [])
        .controller("EfileReturnReviewController",['JsonObjectFactory','GENERAL_CONFIG','SERVER_CONFIG','EfileFactory','$rootScope', '$scope','$state','$stateParams','USER_SETTINGS','EfileCustomGridService',
            function(JsonObjectFactory,GENERAL_CONFIG,SERVER_CONFIG,EfileFactory, $rootScope, $scope,$state,$stateParams,USER_SETTINGS,EfileCustomGridService){
            var vm = this;
            vm.enableReviewProcess = SERVER_CONFIG.configSettings?.enable_review_process?.includes(USER_SETTINGS.user.sso_id) || SERVER_CONFIG.configSettings?.enable_review_process?.toLowerCase() === 'all';

            if(!vm.enableReviewProcess){
                $state.go('submissions-dasboard.submission.dashboard',{id:$stateParams.id});
                return;
            }
            EfileCustomGridService.filters = {};

            loadSteps();

            const cleanup = $rootScope.$on("reload-steps",function(){
                loadSteps();
            });


            $scope.$on("$destroy",function(){
                cleanup();
            });

            function loadSteps(){
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=siao78',{
                    submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID
                }).then(function(data){

                    vm.steps = data.jsonObject ?? [];
                });
            }
        }])
        .controller("EfileCustomGrid", [
            "EfileFactory",
            "USER_SETTINGS",
            "AlertService",
            "JsonObjectFactory",
            "ModalFactory",
            "$uibModal",
            "GENERAL_CONFIG",
            "$stateParams",
            "$scope",
            "$rootScope",
            "workspaceFactory",
            "GridFactory",
            "$timeout",
            "$state",
            "EfileCustomGridService",
            "api_url",
            "params",
            "role",
            "ctrlFn",
            "filters",
            "statusCheckFn",
            EfileCustomGrid
        ]).service("EfileCustomGridService", function(){
            return {
                filters: {}
            }

        });


        function EfileCustomGrid(EfileFactory,USER_SETTINGS,AlertService,JsonObjectFactory,ModalFactory,$uibModal,GENERAL_CONFIG,$stateParams,$scope,$rootScope,workspaceFactory,GridFactory,$timeout,$state,EfileCustomGridService,api_url, params, role, ctrlFn, filters, statusCheckFn){
            
            let vm = this;
            let custom_data_key =api_url+EfileFactory.selectedSubmission.SUBMISSION_ID;
            vm.ssoId = USER_SETTINGS.user.sso_id;
            const JCD_KEY = 250;

            if(!EfileFactory.hasAccess(role)){
                $state.go('submissions-dasboard.submission.dashboard',{id:$stateParams.id});
                return;
            } 

            $scope.ctrlFn = ctrlFn ?? function(_callName, _data){

                    ModalFactory.getModal(_callName,_data).then(function (data) {
                        var modalObj = {};
                        modalObj.template = data.modalScreen.modal_template;
                        modalObj.controler = data.modalScreen.modal_controler;
                        modalObj.size = data.modalScreen.modal_size;
                        modalObj.backdrop = "static"; // added by Omi-07/24/2019(for disabling the modal outside click) //&& data.lockResult.is_locked == "Y"
                        if(data.lockResult && data.lockResult.is_locked  && data.modalScreen.lock_check_required == "Y") {
                            modalObj.windowClass = "modal-window-lock-class";
                            modalObj.modal_lock= data.lockResult.is_locked;
                            modalObj.modal_lock_err_msg = data.lockResult.lock_message;
                            modalObj.lock_check_required = data.modalScreen.lock_check_required;
                        }

                        const rowData = _data.rowData;
                        const gridData = _data;
                        const colData = _data.colData;

                        const modalInstance = $uibModal.open({
                            animation: true,
                            templateUrl: modalObj.template,
                            controller: modalObj.controler,
                            size: modalObj.size,
                            backdrop: modalObj.backdrop, // added by Omi-07/24/2019(for disabling the modal outside click)
                            windowClass: modalObj.windowClass,
                            resolve: {
                                rowData: function () {
                                    return rowData;
                                },
                                colData: function () {
                                    return colData;
                                },
                                gridData: function () {
                                    return gridData;
                                }
                            }
                        });

                        modalInstance.result.then(() => vm.refresh());
                    })
            };
            vm.filters = EfileCustomGridService.filters[api_url] = EfileCustomGridService.filters[api_url] ?? filters;

            function loadingFilters(){

                const promises = [];
                for(const filter of vm.filters){
                    const promise = JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/' + filter.data_url, params).then(function (data) {
                        if(data.callSuccess === "0"){
                            AlertService.add("error", "Error loading filters. please contact support");
                            return;
                        }

                        filter.values = data.jsonObject;
                     });

                     promises.push(promise);
                }

                Promise.all(promises).then(function(){
                    vm.filtersLoaded = true;
                    $scope.$apply();
                });
            }

            var getData = function (refresh) {
                
                vm.loading = true;
                vm.gridData = [];
                vm.gridData.DATA = [];
                var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);

                if (cachedData && cachedData.data && !refresh) {
                    setTimeout(function() {
                        vm.data = cachedData.data.data;
                    
                        $scope.$broadcast("dataLoaded", vm.data);
                        $scope.$broadcast("showLoader", false);
                            
                    });
                    return true;
                }
                /*  if filters are changed, deleting the existing cached Grid Data*/
                else if (cachedData && cachedData.data && refresh) {
                    for (var i = 0; i < workspaceFactory.customDataCache.length > 0; i++) {
                        if (workspaceFactory.customDataCache[i].data_key === cachedData.data_key) {
                            workspaceFactory.customDataCache.splice(i, 1);
                        }
                    }
                    console.log("cusomCache", workspaceFactory.customDataCache);
                }

                
                
                $scope.$broadcast("showLoader", true);
                // vm.errorParams = '&submission_id='+EfileFactory.selectedSubmission.SUBMISSION_ID;

                for(const filter of vm.filters){
                        params[filter.param_name] = filter.selected.map(f => f.VALUE).join(',');
                }
                workspaceFactory.activeScreen.filters.filterParams = params;
                GridFactory.getDataCustom(GENERAL_CONFIG.base_url + api_url,params)
                .then(function (data) {
                    if (data.callSuccess === "1") {
                        vm.data = data;
                        // $rootScope.$broadcast("collapseLeft", function (event, data) {});
                        $scope.$broadcast("dataLoaded", data);
                        $scope.$broadcast("showLoader", false);

                        workspaceFactory.setCustomDataCache(custom_data_key, {
                            data: vm.data
                        });

                        if($scope.$parent?.ctrl?.steps){

                            let status = 'FAILED';
                            if (statusCheckFn(vm.data.data)) {
                                status = 'RESOLVED'
                            }

                            JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=q05d2n',
                                {
                                    sso_id: USER_SETTINGS.user.sso_id,
                                    submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID,
                                    step_id: $scope.$parent.ctrl.steps.find(s => s.STATE_URL === $state.current.name).WORKFLOW_STEP_ID,
                                    step_status: status
                                }).then(function (response) {

                                    if (response.callSuccess === "1") {
                                        $rootScope.$emit("reload-steps");
                                    }
                                });
                        }
                        
                    } else {
                        AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                    }
                });
                
            };

            
            vm.refresh = function() {
                
                $timeout(function() {
                    getData(true);
                },300)

            }

            if(vm.filters.length === 0){
                vm.refresh();
            }else if(vm.filters.length > 0){
                loadingFilters();
            }

            $rootScope.$on('efile-custom-grid-refresh',()=>{
                vm.refresh();
            })
            
        }

});